import React, { useEffect, useRef, useState } from 'react';
import "./propertiesdetail.css";
import "./propertiesdetailresponsive.css";
import hospitalitylogo from "../../assets/images/hospitalitylogo.jpg";
import TheGreenGallery from '../../components/TheGreenGallery/TheGreenGallery';
import { useLocation } from 'react-router';
import * as actions from "../../redux/actions/index";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import OwlCarousel from "react-owl-carousel";
import image1 from "../../assets/images/TheDirtComm_1.jpg"
import image2 from "../../assets/images/TheDirtComm_2.jpg"
import image3 from "../../assets/images/TheDirtComm_3.jpg"
import { Helmet } from 'react-helmet';

const carouselData = [
    {
        id: "1",
        image: image1
    },
    {
        id: "2",
        image: image2
    },
    {
        id: "3",
        image: image3
    }
]

const PropertiesDetail = () => {

    const dispatch = useDispatch();
    const propertiesData = useSelector((state) => state.properties.data);

    useEffect(() => {
        setScrollSection(location.state)
        dispatch(actions.propertiesContent());
    }, [])

    const location = useLocation();
    const [scrollSection, setScrollSection] = useState()

    const resendentialSecRef = useRef()
    const communitySecRef = useRef()

    useEffect(() => {
        //console.log(scrollSection)
        // window.scrollTo({ top: 0, left: 0 });
        window.onload = function () {
            setScrollSection()
        }

        if (scrollSection == "The_Dirt_Community") {
            resendentialSecRef.current?.scrollIntoView({ behavior: 'smooth' });
        } else if (scrollSection == "Community_Welfare_Center") {
            communitySecRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else {
            window.scrollTo({ top: 0, left: 0 });
        }

    })


    // Start Community Owl Carousel Responsive Code
    const communityslider = {
        loop: true,
        // margin: 20,
        // nav: true,
        smartSpeed: 3000,
        autoplaySpeed: 6000,
        autoplaySpeed: 2500,
        autoplaytimeout: 1500,
        slidetransition: "linear",
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        autoplay: true,
        // autoplayTimeout: 6000,
        // autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                // stagePadding: 10,
                // autoplay: false,
            },
            768: {
                items: 1,
                nav: false,
                // stagePadding: 100,
            },
        },
    };
    // End Community Owl Carousel Responsive Code
    return (
        <>
          <Helmet>
        <title>1000 Island Properties: Crafted for Wellbeing and Regeneration</title>
        <meta name="description" content="Explore our unique ventures at 1000 Island, spaces
designed to nurture wellbeing and joy, holistic experiences, wellbeing,
and self-renewal. Explore how we partner with individuals to build
greener spaces and systems." />
      </Helmet>
            {/* Start Properties Detail Portion Code Here  */}
            <div className='properties_detail_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7' dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                propertiesData[1] ? propertiesData[0].strPropertyDescription : ""
                            ),
                        }}>

                        </div>
                        <div className='col-md-5'>
                            <div className='circle_animate'>
                                <div className='hospitalitylogo'>
                                    <img src={hospitalitylogo} alt="Hospitality Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Properties Detail Portion Code Here  */}

            {/* Start Community Welfare Center Portion Code Here  */}
            <div className='community_welfare_center' ref={communitySecRef}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='community_heading'>
                                <span>UPCOMING VENTURE</span>
                                <h1>{propertiesData ? propertiesData[1] ? propertiesData[1].strName : "" : ""}</h1>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <img src={propertiesData ? propertiesData[1] ? propertiesData[1].strImage : "" : ""} alt="Community Welfare Center" className='img-fluid' />
                        </div>
                    </div>
                    <div className='row' dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            propertiesData ? propertiesData[1] ? propertiesData[1].strDescriptionPage : "" : ""
                        ),
                    }} >

                    </div>
                </div>
            </div>
            {/* End Community Welfare Center Portion Code Here  */}

            {/* Start Residential Food Forest Portion Code Here  */}

            <div className='residential_food_forest' ref={resendentialSecRef}>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='residential_heading'>
                                <span>UPCOMING VENTURE</span>
                                <h1>{propertiesData ? propertiesData[2] ? propertiesData[2].strName : "" : ""}</h1>
                            </div>
                        </div>
                        {/* <div className='col-lg-12'>
                            <img src={propertiesData ? propertiesData[1] ? propertiesData[1].strImage : "" : ""} alt="Residential Food Forest" className='img-fluid' />
                        </div> */}
                        <div className='col-lg-12'>
                            <OwlCarousel className="owl-theme" {...communityslider}>
                                {carouselData ? carouselData.map(data => (
                                    <div className="item">
                                        <div className="destination-carousel-item">
                                            <img src={data.image} alt="woods img" />
                                        </div>
                                    </div>
                                )) : ""}
                            </OwlCarousel>
                        </div>
                    </div>
                    <div className='row' dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            propertiesData ? propertiesData[2] ? propertiesData[2].strDescriptionPage : "" : ""
                        ),
                    }} />


                </div>
            </div>


            {/* End Residential Food Forest Portion Code Here  */}





            {/* Start The Green Gallery Slider Portion Code Here  */}
            {/* <TheGreenGallery /> */}
            {/* End The Green Gallery Slider Portion Code Here  */}

        </>
    )
}

export default PropertiesDetail
