import { combineReducers } from "redux"
import { aboutContent, awardData, communitySliderData, galleryData, propertiesData, pustakGharData,
     somData, tourismSliderData, wellbeingContent,destinationData } from "./reducer";

const rootReducer = combineReducers({
    about: aboutContent,
    wellbeing: wellbeingContent,
    properties: propertiesData,
    gallery: galleryData,
    tourismSlider: tourismSliderData,
    communitySlider: communitySliderData,
    pustakGhar: pustakGharData,
    somData: somData,
    awardData: awardData,
    destinationData:destinationData
});

export default rootReducer