import axios from "axios";
import { token } from "../../services/apiConfig";
import {
    aboutContentRoute,
    awardDataRoute,
    communitySliderDataRoute,
    galleryDataRoute,
    propertiesDataRoute,
    pustakGharDataRoute,
    somDataRoute,
    toursimSliderDataRoute,
    wellbeingRoute,
    destinationDataRoute
} from "../../services";



export const getAboutContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: aboutContentRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(aboutContentAction(res.data.data));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const aboutContentAction = (data) => {
    return {
        type: "GET_ABOUT_CONTENT_DATA",
        data: data
    }
}




export const getwellbeingContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: wellbeingRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(wellbeingAction(res.data));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const wellbeingAction = (data) => {
    return {
        type: "GET_WELLBEING_CONTENT_DATA",
        data: data
    }
}


export const propertiesContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: propertiesDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(propertiesAction(res.data.content));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const propertiesAction = (data) => {
    return {
        type: "GET_PROPERTIES_CONTENT_DATA",
        data: data
    }
}

export const galleryContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: galleryDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(galleryAction(res.data.content));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const galleryAction = (data) => {
    return {
        type: "GET_GALLERY_DATA",
        data: data
    }
}




export const toursimSliderContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: toursimSliderDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(toursimSliderAction(res.data.content));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const toursimSliderAction = (data) => {
    return {
        type: "GET_TOURISM_SLIDER_DATA",
        data: data
    }
}


export const communitySliderContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: communitySliderDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(communitySliderAction(res.data.data));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const communitySliderAction = (data) => {
    return {
        type: "GET_COMMUNITY_SLIDER_DATA",
        data: data
    }
}


export const pustakGharContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: pustakGharDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(pustakGharAction(res.data.data));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const pustakGharAction = (data) => {
    return {
        type: "GET_PUSTAK_GHAR_DATA",
        data: data
    }
}


export const somContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: somDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data)
                    if (res.data.status == 200) {
                        dispatch(somAction(res.data.data));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const somAction = (data) => {
    return {
        type: "GET_SOM_DATA",
        data: data
    }
}


export const awardContent = () => async(dispatch) => {

    await axios({
        method: "post",
        url: awardDataRoute,
        data: token
    })

    .then((res) => {
            if (res) {
                try {
                    // console.log("res", res.data.content)
                    if (res.data.status == 200) {
                        dispatch(awardAction(res.data.content));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const awardAction = (data) => {
    return {
        type: "GET_AWARD_DATA",
        data: data
    }
}



//Destination
export const destinationContent = () => async(dispatch) => {
    await axios({
            method: "post",
            url: destinationDataRoute,
            data: token
        }).then((res) => {
            if (res) {
                try {
                    if (res.data.status == 200) {
                        dispatch(destinationDataAction(res.data.content));
                        return false;
                    } else {
                        console.log(res.data.message)
                    }

                } catch (exc) {
                    console.log("exception", exc);
                }
            }
        })
        .catch((e) => {
            console.log("catch error", JSON.stringify(e));
        });
}

export const destinationDataAction = (data) => {
        return {
            type: "GET_DESTINATION_CONTENT_DATA",
            data: data
        }
    }
    //End