import $ from "jquery";

// Start Navbar in AddClass Or RemoveClass Jquery Code Here 
$(document).on("click", "nav .navbar-collapse .navbar-nav .nav-item", function () {
    $('nav .navbar-collapse .navbar-nav .nav-item').removeClass("active");
    $(this).addClass("active");
});
// End Navbar in AddClass Or RemoveClass Jquery Code Here


// Start Navbar Toggle Menu Design and Body Overflow:hidden Code 
$(document).on("click", ".navbar-toggler", function () {
    // alert("Hello")
    $("body").toggleClass("scrolling-hidden");
    $(".navbar-toggler svg").toggleClass("active");
});
// End Navbar Toggle Menu Design and Body Overflow:hidden Code