import './assets/js/custom.js';
import '../src/App.css';
import './assets/css/fontfamily.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/fontawesome-free-5.15.4-web/css/all.min.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layouts/Layout';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs.js';
import PropertiesDetail from './pages/PropertiesDetail/PropertiesDetail';
import PageNotFound from './pages/PageNotFound/PageNotFound.js';

function App() {
  return (
    <div className="App">
      
      <Router basename={"/"}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/properties" element={<PropertiesDetail />} />
          </Route>
          {/* <Route path="/laravel-1000island-app/webpanel/*"  /> */}

          <Route path="*" element={<PageNotFound />}  />

        </Routes >
      </Router>
    </div>
  );
}
export default App;