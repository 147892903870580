import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import "../AwardList/awardlist.css";
import * as actions from "../../redux/actions/index";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

function AwardList() {
    const dispatch = useDispatch();
    const awardData = useSelector((state) => state.awardData.data);
    useEffect(() => {
        dispatch(actions.awardContent());
        // console.log(awardData)
    }, [dispatch]);

    return (
        <>
            {/* Start Award List Portion Code Here  */}
            <div className='award_list'>
                <div className='container'>
                    <div className='row'>
                        {awardData ? awardData.map((data, index) => (
                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <Card>
                                    <Card.Img variant="top" src={data.strImage} />
                                    <Card.Body>
                                        <Card.Title dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                data.strTitle
                                            ),
                                        }}></Card.Title>

                                    </Card.Body>
                                </Card>
                            </div>
                        )) : ""}
                    </div>
                </div>
            </div>
            {/* End Award List Portion Code Here  */}

        </>
    )
}

export default AwardList