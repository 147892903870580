import React,{useEffect} from 'react';
import "../DestinationSlider/destinationslider.css";
import "../DestinationSlider/destinationsliderresponsive.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import * as actions from "../../redux/actions/index";
import { useDispatch, useSelector } from 'react-redux';


const DestinationSlider = () => {
    const dispatch = useDispatch();
    const destinationData = useSelector((state) => state.destinationData.data);  

    useEffect(() => { 
        dispatch(actions.destinationContent());
    }, [dispatch]);

    //console.log(destinationData)
    // Start Destination Owl Carousel Responsive Code
    const destinationslider = {
        loop: true,
        // margin: 20,
        // nav: true,
        smartSpeed: 3000,
        autoplaySpeed: 6000,
        autoplaySpeed: 2500,
        autoplaytimeout: 1500,
        slidetransition: "linear",
        autoplay: true,
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        // autoplayTimeout: 6000,
        // autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                // stagePadding: 10,
                // autoplay: false,
            },
            768: {
                items: 1,
                nav: false,
                // stagePadding: 100,
            },
        },
    };
    // End Destination Owl Carousel Responsive Code

    return (
        <>
            {/* Start Destination First Carousel Portion Code Here */}
            <div className='destination_slider'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='destination_heading'>
                                <h1>Destination First</h1>
                                <h1 className='sub_heading'>by 1000 Island</h1>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <OwlCarousel className="owl-theme" {...destinationslider}>
                                {destinationData?destinationData.map((data,index)=>(                                
                                <div className="item">
                                    <div className="destination-carousel-item">
                                        <img src={data.strImage} alt="woods img" />
                                    </div>
                                </div>
                               )):''}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Destination First Carousel Portion Code Here */}
        </>
    )
}
export default DestinationSlider