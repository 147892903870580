import React, { useEffect, useState } from 'react';
import islandbanner from "../../assets/images/islandbanner.jpg";
import hospitalitylogo from "../../assets/images/hospitalitylogo.jpg";
import somlogo from "../../assets/images/somlogo.png";
import somwellbeing from "../../assets/images/somwellbeing.jpg";
import './home.css';
import "./homeresponsive.css";
import { Button } from 'react-bootstrap';
import Properties from '../../components/Properties/Properties';
import DestinationSlider from '../../components/DestinationSlider/DestinationSlider';
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/actions/index";
import DOMPurify from "dompurify";
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
  const dispatch = useDispatch();
  const aboutData = useSelector((state) => state.about.data);
  const wellbeingData = useSelector((state) => state.wellbeing.data.content);
  const somData = useSelector((state) => state.somData.data);  

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0 });
  })
  useEffect(() => {
    dispatch(actions.getAboutContent());
    dispatch(actions.getwellbeingContent());
    dispatch(actions.somContent());

  }, [dispatch]);


  const navigate = useNavigate();

  const navagateAbout = (data) => {
    navigate('/about', { state: data });
  }



  return (
    <>
    <Helmet>
    <title>Discover 1000 Islands: Modern Indian Hospitality Ventures
for Wellbeing and Joy</title>
    <meta name="description" content="Explore 1000 Islands, where we manage design-led
hospitality ventures dedicated to mindfulness, wellbeing, and joy.
From a regenerative retreat to the community-forward center and a
mindful Community, we empower individuals with regenerative
choices, foster strong communities, and prioritize the planet." />
  </Helmet>
      {/* Start Home Page Portion Code Here   */}
      <div className='island_banner'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <img src={aboutData?.home_banner} alt="Island Banner" />
            </div>
          </div>
        </div>
      </div>
      {/* End Home Page Portion Code Here   */}


      {/* Start Top 1000island Logo & Text Portion Code Here  */}
      <div className='top_island_logo_text'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 col-md-7'>
              <p dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  aboutData?.home_description
                ),
              }} />

              <Button className='read_more_btn' onClick={() => navagateAbout(null)}>read more<span></span></Button>
            </div>
            <div className='col-lg-5 col-md-5'>
              <div className='circle_animate'>
                <div className='hospitalitylogo'>
                  <img src={hospitalitylogo} alt="Hospitality Logo" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* End Top 1000island Logo & Text Portion Code Here  */}

      {/* Start Properties Portion Code Here */}
      <Properties />
      {/* End Properties Portion Code Here */}


      {/* Start Som Wellbeing Portion Code Here  */}
      <div className='som_wellbeing'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5 col-md-6'>
              <h4>{somData?.strMainTitle}</h4>
            </div>

            <div className='col-12'>
              <div className='som_wellbeing_box'>
                <div className='som_img som_box'>
                  <img src={somData?.strImage} alt="Som Wellbeing Logo" />
                </div>
                <div className='som_box'>
                  <div className='som_inner'>
                    <div className='somlogo'>
                      {/* somData?.strLogo */}
                      <img src={somlogo} alt="Som Wellbeing Logo" />
                    </div>

                    <h3>{somData?.strTitle}</h3>
                    <p dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        somData?.strDescription
                      ),
                    }}></p>
                    <Link to="https://www.woodsatsasan.com/wellness" target='_blank' ><Button className='read_more_btn'>VISIT THE SITE<span></span></Button></Link>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* End Som Wellbeing Portion Code Here  */}


      {/* Start Som Tab Box Portion Code Here  */}
      <div className='som_tab_box'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='som_tab'>
                {wellbeingData?.map((data, index) => (
                  <div className='som_tab_content' onClick={() => navagateAbout(data.strTitle)}>
                    <p>{data.strHomeDescription}</p>
                    <h2>{data.strTitle}</h2>
                    <span>{index + 1}</span>
                  </div>
                ))}

              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-5 col-md-6'>
              <div className='som_heading'>
                <h4>Wellbeing and joy for the individual, the community and the planet.</h4>

                <p>We work toward this by respect for the location, people and the environment, fuelled by our passion to create meaningful experiences that inspire our guests. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Som Tab Box Portion Code Here  */}



      {/* Start Destination First Carousel Portion Code Here  */}
      <DestinationSlider />
      {/* End Destination First Carousel Portion Code Here  */}
    </>
  )
}

export default Home
