
import React, { useEffect, useRef, useState } from 'react';
import "./aboutus.css";
import "./aboutusresponsive.css";
import hospitalitylogo from "../../assets/images/hospitalitylogo.jpg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from "../../redux/actions/index";
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom';
import AwardList from '../../components/AwardList/AwardList';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const aboutData = useSelector((state) => state.about.data);
    const wellbeingData = useSelector((state) => state.wellbeing.data.content);
    const tourismSliderData = useSelector((state) => state.tourismSlider.data);
    const communitySliderData = useSelector((state) => state.communitySlider.data);
    const pustakGharData = useSelector((state) => state.pustakGhar.data);

    const [scrollSection, setScrollSection] = useState()



    useEffect(() => {
        setScrollSection(location.state)
        dispatch(actions.getAboutContent());
        dispatch(actions.getwellbeingContent());
        dispatch(actions.toursimSliderContent());
        dispatch(actions.communitySliderContent());
        dispatch(actions.pustakGharContent());
        // console.log(pustakGharData)
    }, [dispatch]);

    const individualSecRef = useRef()
    const communitySecRef = useRef()
    const planetSecRef = useRef()


    useEffect(() => {
        // window.scrollTo({ top: 0, left: 0 });
        window.onload = function () {
            setScrollSection()
        }
        if (scrollSection == "INDIVIDUAL") {
            // window.scrollTo({
            //     top: individualSecRef.current ? individualSecRef.current : "",
            //     left: 0,
            //     behavior: "smooth",
            // });

            individualSecRef.current?.scrollIntoView({ behavior: 'smooth', block: "start" });
        } else if (scrollSection == "COMMUNITY") {
            communitySecRef.current?.scrollIntoView({ behavior: 'smooth', block: "start" })
        } else if (scrollSection == "PLANET") {
            planetSecRef.current?.scrollIntoView({ behavior: 'smooth', block: "start" })
        } else {
            window.scrollTo({ top: 0, left: 0 });
        }

    })




    // Start Community Owl Carousel Responsive Code
    const communityslider = {
        loop: true,
        // margin: 20,
        // nav: true,
        smartSpeed: 3000,
        autoplaySpeed: 6000,
        autoplaySpeed: 2500,
        autoplaytimeout: 1500,
        slidetransition: "linear",
        animateOut: "fadeOut",
        animateIn: "fadeIn",
        autoplay: true,
        // autoplayTimeout: 6000,
        // autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                // stagePadding: 10,
                // autoplay: false,
            },
            768: {
                items: 1,
                nav: false,
                // stagePadding: 100,
            },
        },
    };
    // End Community Owl Carousel Responsive Code
    return (
        <>
        <Helmet>
        <title>1000 Island: Where Destination Comes First - About Us</title>
        <meta name="description" content="Discover our philosophy at 1000 Island, where we
prioritize the natural beauty, culture, and communities of each
destination. Join us in putting the soul back into hospitality, fostering
strong community bonds, and practicing regenerative tourism for a
better planet." />
      </Helmet>

            {/* Start About Us Portion Code Here  */}
            <div className='about_header_banner'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-7' dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                aboutData?.str_about_description
                            ),
                        }} />

                        <div className='col-md-5'>
                            <div className="circle_animate">
                                <div className="hospitalitylogo">
                                    <img src={hospitalitylogo} alt="Hospitality Logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End About Us Portion Code Here  */}


            {/* Start 1000 Islanders Portion Code Here  */}
            <div className='islanders_team'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <img src={aboutData?.str_about_img1} className='first' alt="1000 Islanders Team" />
                        </div>
                        <div className='col-sm-6'>
                            <img src={aboutData?.str_about_img2} className='last' alt="1000 Islanders Team" />
                        </div>
                    </div>
                    <div className='row' ref={individualSecRef}>
                        <div className='col-lg-12'>
                            <div className='team_heading'>
                                <span>{wellbeingData ? wellbeingData[0].strTitle : ""}</span>
                                <h1>{wellbeingData ? wellbeingData[0].strAboutTitle : ""}</h1>
                            </div>
                        </div>
                    </div>
                    <div className='row' dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            wellbeingData ? wellbeingData[0].strAboutDescription : ""
                        ),
                    }}>

                    </div>
                </div>
            </div>
            {/* End 1000 Islanders Portion Code Here  */}

            {/* Start Our Community Portion Code Here  */}
            <div className='community_section'>
                <div className='container'>
                    <div className='row' ref={communitySecRef}>
                        <div className='col-lg-12'>
                            <div className='community_heading' >
                                <span>{wellbeingData ? wellbeingData[1].strTitle : ""}</span>
                                <h1>{wellbeingData ? wellbeingData[1].strAboutTitle : ""}</h1>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <OwlCarousel className="owl-theme" {...communityslider}>
                                {communitySliderData ? communitySliderData.map(data => (
                                    <div className="item">
                                        <div className="destination-carousel-item">
                                            <img src={data.str_img} alt="woods img" />
                                        </div>
                                    </div>
                                )) : ""}
                            </OwlCarousel>
                        </div>


                    </div>
                    <div className='row' dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            wellbeingData ? wellbeingData[1].strAboutDescription : ""
                        ),
                    }} />
                </div>

            </div>
            {/* End Our Community Portion Code Here  */}

            {/* Start Pustak Ghar Portion Code Here  */}
            <div className='pustakghar'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div class="pustakghar_box"><img src={pustakGharData?.str_img} alt="Pustak Ghar" /></div>
                        </div>
                        <div className='col-lg-6' dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                                pustakGharData ? pustakGharData.str_description : ""
                            ),
                        }}>


                        </div>
                    </div>
                </div>
            </div>
            {/* End Pustak Ghar Portion Code Here  */}



            {/* Start Regenerative Tourism Portion Code Here  */}
            <div className=' mb-r-5 regenerative_section'>
                <div className='container'>
                    <div className='row' ref={planetSecRef}>
                        <div className='col-lg-12'>
                            <div className='regenerative_heading'>
                                <span>{wellbeingData ? wellbeingData[2].strTitle : ""}</span>
                                <h1>{wellbeingData ? wellbeingData[2].strAboutTitle : ""}</h1>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <OwlCarousel className="owl-theme" {...communityslider}>
                                {tourismSliderData ? tourismSliderData.map(data => (
                                    <div className="item">
                                        <div className="destination-carousel-item">
                                            <img src={data.strImage} alt="woods img" />
                                        </div>
                                    </div>
                                )) : ""}

                            </OwlCarousel>
                        </div>


                    </div>
                    <div className='row' dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                            wellbeingData ? wellbeingData[2].strAboutDescription : ""
                        ),
                    }}>
                    </div>
                </div>

            </div>
            {/* End Regenerative Tourism Portion Code Here  */}
            {/* Award List Component */}
            <AwardList />
            {/* Award List Component */}
        </>
    )
}

export default AboutUs
