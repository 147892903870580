import React, { useEffect } from 'react';
import "./properties.css";
import { Card, Button } from 'react-bootstrap';
import card1 from "../../assets/images/card1.jpg";
import card2 from "../../assets/images/card2.jpg";
import card3 from "../../assets/images/card3.jpg";
import './propertiesresponsive.css';
import { Link, useNavigate } from "react-router-dom";
import * as actions from "../../redux/actions/index";
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';

const Properties = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const propertiesData = useSelector((state) => state.properties.data);

    useEffect(() => {
        dispatch(actions.propertiesContent());
    }, [])
    const navigateToProperties = (data) => {
        navigate('/properties', { state: data.replace(/ /g, "_") });
    };
    if (propertiesData) {
        if (propertiesData.length > 0) {
            for (let i = 0; i < propertiesData.length; i++) {
                propertiesData[i].strDetails = propertiesData[i].strDetails.replace('<p>', "")
                propertiesData[i].strDetails = propertiesData[i].strDetails.replace('</p>', "")
            }
        }
    }
    return (
        <>
            {/* Start Properties Portion Code Here  */}
            <div className='properties_box'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='p_heading'>Properties</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={(propertiesData ? propertiesData[2] ? propertiesData[0].strUrl : "" : " ")}
                                target="_blank"
                            >
                                <Card >
                                    <div className='card_img'>
                                        <Card.Img variant="top" src={propertiesData ? propertiesData[0] ? propertiesData[0].strHomeImage : "" : " "} />
                                        <div className='overlay'>
                                            <h4 dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    propertiesData[1] ? propertiesData[0].strName : ""
                                                ),
                                            }}></h4>
                                        </div>
                                    </div>

                                    <Card.Body>

                                        <Card.Text>
                                            {propertiesData ? propertiesData[0] ? propertiesData[0].strDetails : "" : ""}
                                        </Card.Text>
                                        <Button className='read_more_btn' >visit the site<span></span></Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </div>
                        {propertiesData ? propertiesData.slice(1).map(data => (
                            <div className='col-md-4'>

                                <Card onClick={() => navigateToProperties(data.strName)}>
                                    <div className='card_img'>
                                        <Card.Img variant="top" src={data.strHomeImage} />
                                        <div className='overlay'>
                                            <h4>{data.strName}</h4>
                                        </div>
                                    </div>

                                    <Card.Body>

                                        <Card.Text>
                                            {data.strDetails}
                                        </Card.Text>
                                        <Button className='know_more_btn'>know more<span></span></Button>
                                    </Card.Body>
                                </Card>
                            </div>
                        )) : ""}

                    </div>
                </div>
            </div>
            {/* End Properties Portion Code Here  */}

        </>
    )
}

export default Properties
