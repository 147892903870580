//const rootHost = "http://192.168.30.83/laravel-1000island-app/api";
//const rootHost = "http://192.168.30.75/laravel-1000island-app/api";
//const rootHost = "http://183.87.214.73:8080/laravel-1000island-app/api";
const rootHost = "https://1000island.in/laravel-1000island-app/api";
//const rootHost = "http://139.59.85.20/laravel-1000island-app/api";


export const aboutContentRoute = `${rootHost}/aboutcontent`;
export const wellbeingRoute = `${rootHost}/wellbeing`;
export const propertiesDataRoute = `${rootHost}/getpropertiesdata`;
export const galleryDataRoute = `${rootHost}/getgallerydata`;
export const toursimSliderDataRoute = `${rootHost}/tourism_slider_image`;
export const communitySliderDataRoute = `${rootHost}/community_slider_image`;
export const pustakGharDataRoute = `${rootHost}/pustak_ghar_content`;
export const somDataRoute = `${rootHost}/som`;
export const awardDataRoute = `${rootHost}/getAwardLogo`;
export const destinationDataRoute = `${rootHost}/getdestinationdata`;