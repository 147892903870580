const initialState = {
    data: [],
};
export const aboutContent = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ABOUT_CONTENT_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export const wellbeingContent = (state = initialState, action) => {
    switch (action.type) {
        case "GET_WELLBEING_CONTENT_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}


export const propertiesData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PROPERTIES_CONTENT_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export const galleryData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_GALLERY_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}


export const tourismSliderData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TOURISM_SLIDER_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}


export const communitySliderData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_COMMUNITY_SLIDER_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}



export const pustakGharData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PUSTAK_GHAR_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}



export const somData = (state = initialState, action) => {
    switch (action.type) {
        case "GET_SOM_DATA":
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}


export const awardData = (state = initialState, action) => {
        switch (action.type) {
            case "GET_AWARD_DATA":
                return {
                    ...state,
                    data: action.data
                }
            default:
                return state;
        }
    }
    //Destination
export const destinationData = (state = initialState, action) => {
        switch (action.type) {
            case "GET_DESTINATION_CONTENT_DATA":
                return {
                    ...state,
                    data: action.data
                }
            default:
                return state;
        }
    }
    //End here