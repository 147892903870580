import React from 'react';
import "./pagenotfound.css";
import logo from "../../assets/images/logo_1000.png";
import { Link } from 'react-router-dom';
const PageNotFound = () => {
    return (
        <>
            <div class="errorpage">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="error_head">
                                <div class="errorpage_box">
                                    <h1>404</h1>
                                    <h4 class="msg">NOT FOUND</h4>
                                </div>
                                <div class="error_footer">
                                    <Link to={"/"}>
                                        <img src={logo} class="logo" alt="woodslogo" />
                                        <p>Go Back to Home</p>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PageNotFound